import image_1 from "../gallery/gallery_webp/bramy/photo_1.webp";
import image_2 from "../gallery/gallery_webp/bramy/photo_2.webp";
import image_3 from "../gallery/gallery_webp/bramy/photo_3.webp";
import image_4 from "../gallery/gallery_webp/bramy/photo_4.webp";
import image_5 from "../gallery/gallery_webp/bramy/photo_5.webp";
import image_6 from "../gallery/gallery_webp/bramy/photo_6.webp";
import image_7 from "../gallery/gallery_webp/bramy/photo_7.webp";
import image_8 from "../gallery/gallery_webp/bramy/photo_8.webp";

export const images = [
	{
		src: image_1,
		original: image_1,
		width: 3,
		height: 4,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 1",
	},
	{
		src: image_2,
		original: image_2,
		width: 3,
		height: 4,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 2",
	},
	{
		src: image_3,
		original: image_3,
		width: 3,
		height: 4,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 3",
	},
	{
		src: image_4,
		original: image_4,
		width: 4,
		height: 3,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 4",
	},
	{
		src: image_5,
		original: image_5,
		width: 3,
		height: 4,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 5",
	},
	{
		src: image_6,
		original: image_6,
		width: 4,
		height: 3,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 6",
	},
	{
		src: image_7,
		original: image_7,
		width: 16,
		height: 9,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 7",
	},
	{
		src: image_8,
		original: image_8,
		width: 16,
		height: 9,
		// tags: [
		// 	{ value: "Nature", title: "Nature" },
		// 	{ value: "People", title: "People" },
		// ],
		caption: "Bramy 8",
	},
];
