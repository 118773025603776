import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import logo_image from "../../assets/pt_zaune_no_bg.png";

import "./index.scss";

const Contact = (props) => {
	return (
		<div className="contact">
			<div className="parts">
				<div className="part part_1">
					<p>
						<FontAwesomeIcon icon={faPhone} color="#000" />
						<b>phones</b>
					</p>
					<ul>
						<li>661-378-308</li>
						<li>513-054-825</li>
						<li>+49 157 81884671</li>
					</ul>
				</div>
				<div className="part part_2">
					<p>
						<FontAwesomeIcon icon={faWhatsapp} size="lg" color="#000" />
						<b>whatsapp</b>
					</p>
					<ul>
						<li>661-378-308</li>
						<li>513-054-825</li>
						<li>+49 157 81884671</li>
					</ul>
				</div>
				<div className="part part_3">
					<p>
						<FontAwesomeIcon icon={faEnvelope} size="lg" color="#000" />
						<b>e-mail</b>
					</p>
					<ul>
						<li>pt.infozaune@gmail.com</li>
						<li>contact@ptzaune.de</li>
					</ul>
				</div>
				<div className="part part_4">
					<p>
						<a
							href="https://www.facebook.com/profile.php?id=100084114120455"
							target={"_blank"}
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faFacebook} size="lg" color="#000" />
							<b>facebook</b>
						</a>
					</p>
					<ul>
						<li>
							<a
								href="https://www.facebook.com/profile.php?id=100084114120455"
								target={"_blank"}
								rel="noreferrer"
							>
								PT Zaune
							</a>
						</li>
					</ul>
				</div>
			</div>
			<img src={logo_image} alt="" />
		</div>
	);
};

export default Contact;
