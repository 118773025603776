import "./index.scss";

import { NavLink } from "react-router-dom";

const Nav = ({ lang }) => {
	return (
		<div className="Nav">
			<NavLink to="/">home</NavLink>
			<NavLink to="/ogrodzenia">
				{lang === "pl" ? "ogrodzenia" : "zäune"}
			</NavLink>
			<NavLink to="/balustrady">
				{lang === "pl" ? "balustrady" : "geländer"}
			</NavLink>
			<NavLink to="/bramy">{lang === "pl" ? "bramy" : "tore"}</NavLink>
			<NavLink to="/contact">kontakt</NavLink>
			<a
				href="https://www.facebook.com/profile.php?id=100084114120455"
				target="_blank"
				rel="noreferrer"
			>
				{lang === "pl" ? "opinie" : "meinungen"}
			</a>
		</div>
	);
};

export default Nav;
