import Main from "../Main";
import Tiles from "../Tiles";
import Aside from "../Aside";

import "./index.scss";

const Home = ({ lang }) => {
	return (
		<>
			<Main lang={lang} />
			<Tiles lang={lang} />
			<div className="container">
				<Aside lang={lang} />
				{/* <Gallery /> */}
			</div>
		</>
	);
};

export default Home;
