import image_1 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (1).webp";
import image_2 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (2).webp";
import image_3 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (3).webp";
import image_4 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (4).webp";
import image_5 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (5).webp";
import image_6 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (6).webp";
import image_7 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (7).webp";
import image_8 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (8).webp";
import image_9 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (9).webp";
import image_10 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (10).webp";
import image_11 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (11).webp";
import image_12 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (12).webp";
import image_13 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (13).webp";
import image_14 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (14).webp";
import image_15 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (15).webp";
import image_16 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (16).webp";
import image_17 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (17).webp";
import image_18 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (18).webp";
import image_19 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (19).webp";
import image_20 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (20).webp";
import image_21 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (21).webp";
import image_22 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (22).webp";
import image_23 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (23).webp";
import image_24 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (24).webp";
import image_25 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (25).webp";
import image_26 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (26).webp";
import image_27 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (27).webp";
import image_28 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (28).webp";
import image_29 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (29).webp";
import image_30 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (30).webp";
import image_31 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (31).webp";
import image_32 from "../gallery/gallery_webp/ogrodzenia/ogrodzenia_ (32).webp";

export const images = [
	{
		src: image_1,
		original: image_1,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 1",
	},
	{
		src: image_2,
		original: image_2,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 2",
	},
	{
		src: image_3,
		original: image_3,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 3",
	},
	{
		src: image_4,
		original: image_4,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 4",
	},
	{
		src: image_5,
		original: image_5,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 5",
	},
	{
		src: image_6,
		original: image_6,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 6",
	},
	{
		src: image_7,
		original: image_7,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 7",
	},
	{
		src: image_8,
		original: image_8,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 8",
	},
	{
		src: image_9,
		original: image_9,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 9",
	},
	{
		src: image_10,
		original: image_10,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 10",
	},
	{
		src: image_11,
		original: image_11,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 11",
	},
	{
		src: image_12,
		original: image_12,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 12",
	},
	{
		src: image_13,
		original: image_13,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 13",
	},
	{
		src: image_14,
		original: image_14,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 14",
	},
	{
		src: image_15,
		original: image_15,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 15",
	},
	{
		src: image_16,
		original: image_16,
		width: 4,
		height: 3,
		caption: "Ogrodzenia 16",
	},
	{
		src: image_17,
		original: image_17,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 17",
	},
	{
		src: image_18,
		original: image_18,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 18",
	},
	{
		src: image_19,
		original: image_19,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 19",
	},
	{
		src: image_20,
		original: image_20,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 20",
	},
	{
		src: image_21,
		original: image_21,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 21",
	},
	{
		src: image_22,
		original: image_22,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 22",
	},
	{
		src: image_23,
		original: image_23,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 23",
	},
	{
		src: image_24,
		original: image_24,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 24",
	},
	{
		src: image_25,
		original: image_25,
		width: 3,
		height: 4,
		caption: "Ogrodzenia 25",
	},
	{
		src: image_26,
		original: image_26,
		width: 9,
		height: 16,
		caption: "Ogrodzenia 26",
	},
	{
		src: image_27,
		original: image_27,
		width: 9,
		height: 16,
		caption: "Ogrodzenia 27",
	},
	{
		src: image_28,
		original: image_28,
		width: 4,
		height: 3,
		caption: "Ogrodzenia 28",
	},
	{
		src: image_29,
		original: image_29,
		width: 16,
		height: 9,
		caption: "Ogrodzenia 29",
	},
	{
		src: image_30,
		original: image_30,
		width: 4,
		height: 3,
		caption: "Ogrodzenia 30",
	},
	{
		src: image_31,
		original: image_31,
		width: 4,
		height: 3,
		caption: "Ogrodzenia 31",
	},
	{
		src: image_32,
		original: image_32,
		width: 4,
		height: 3,
		caption: "Ogrodzenia 32",
	},
];
