import "./index.scss";

const Aside = ({ lang }) => {
	return (
		<div className="Aside">
			<p>
				{lang === "pl"
					? "Wersja polska wkrótce"
					: "Firma PT Zäune Wir sind ein kompetenter Partner, wenn es um Montage der Metallzäune Tore, Pforten und Geländer geht. Wir bieten hochwertige Produkte mit Deutscher Qualität zu polnischen Preisen an. Wir bestehen seit 8 Jahren auf dem Markt. Wir führen die Montage mit kundeneigenem oder eigenem Material durch. Zusätzlich bieten wir die Möglichkeit, das benötigte Zaunmaterial bei uns zu bestellen. Rufen Sie uns an, wenn Sie noch Fragen haben"}
			</p>
			<b>Tel. +49 15781884671</b>
			<br />
			<b>Tel. +48 661 378 308</b>
		</div>
	);
};

export default Aside;
