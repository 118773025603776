import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

import "./App.scss";
import Nav from "./components/Nav";
import Top from "./components/Top";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Ogrodzenia from "./components/Ogrodzenia";
import Balustrady from "./components/Balustrady";
import Bramy from "./components/Bramy";

function App() {
	const [lang, setLang] = useState("de");

	const handleSetLang = (choice) => setLang(choice);

	return (
		<Router>
			<div className="container">
				<Top setLang={handleSetLang} />
				<Nav lang={lang} />
				<Routes>
					<Route path="/contact" element={<Contact />} />
					<Route path="/ogrodzenia" element={<Ogrodzenia />} />
					<Route path="/balustrady" element={<Balustrady />} />
					<Route path="/bramy" element={<Bramy />} />
					<Route lang={lang} exact path="/" element={<Home lang={lang} />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
