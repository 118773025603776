import { Link } from "react-router-dom";

import "./index.scss";

const Top = (props) => {
	return (
		<div className="Top">
			<Link to="/" className="logo"></Link>
			<div className="langChange">
				<img
					src="https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg"
					onClick={() => props.setLang("pl")}
				/>
				<img
					src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png"
					onClick={() => props.setLang("de")}
				/>
			</div>
		</div>
	);
};

export default Top;
