import "./index.scss";

const Main = ({ lang }) => {
	return (
		<div className="Main">
			<p>PT Zäune</p>
			<p>{lang === "pl" ? "Wersja polska wkrótce" : "Zäune Geländer tore"}</p>
			<p>
				{lang === "pl" ? "Wersja polska wkrótce" : "Produktion und Montage"}
			</p>
		</div>
	);
};

export default Main;
