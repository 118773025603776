import "./index.scss";
import { Link } from "react-router-dom";

const Tiles = ({ lang }) => {
	return (
		<div className="Tiles">
			<Link className="tile" to="/ogrodzenia">
				<p>{lang === "pl" ? "Ogrodzenia" : "Zäune"}</p>
			</Link>
			<Link className="tile" to="/balustrady">
				<p>{lang === "pl" ? "Balustrady" : "Geländer"}</p>
			</Link>
			<Link className="tile" to="/bramy">
				<p>{lang === "pl" ? "Bramy" : "Tore"}</p>
			</Link>
		</div>
	);
};

export default Tiles;
